footer {
  padding-top: 20px;
  background-color: #262b57;
  color: rgba(189, 185, 240, 1);
  font-family: "Open Sans", sans-serif;
}

footer.footer-downloads {
  background: #202342;
}

footer div#logo img.logo-image {
  width: 86px;
  height: 42px;
}

footer p#footer-desc {
  color: #ffffff;
  font-size: 18px;
}

footer li a:hover {
  color: #00e9e7;
}

form {
  display: flex;
  flex-direction: column;
  /*border: 1px solid grey; */
  /* padding: 1px; */
}

input.email-field {
  flex-grow: 2;
  border: none;
  border-radius: 32px;
  padding-left: 5%;
  padding-right: 40%;
  /*To disallow contents from going behind the button*/
  height: 52px;
  color: #000000;
}

div.email-container>h5.newsletter {
  text-align: end;
  margin-right: 8.7%;
}

input.email-field:focus {
  outline: none;
  border: 1px solid #0044C6;
}

div.footer-sec {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 90%;
  padding-top: 20px;
}

p.address,
p.email-address {
  font-size: 16px;
  margin-top: 0 !important;
  /* color: palevioletred; */
}

.contact-items {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Adjust as needed */
}

.loc-icon {
  align-items: center;
  justify-content: flex-start;
  margin-bottom: auto;
  margin-top: 4px;
}

.mail-icon {
  margin-top: 0;
}


p.country {
  font-weight: 600;
  margin-top: 0 !important;
  padding-top: 5px;
  font-size: 18px;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: "Josefin Sans", sans-serif;
  color: rgba(0, 233, 231, 1);
}

footer h5 {
  font-size: 20px;
}

footer p {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  text-align: left;
}

footer>div.footer-sec ul li {
  font-size: 16px;
}

div.copyrights>p {
  margin-bottom: 0 !important;
  font-size: 16px;
}

footer div.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.email-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-left: auto;
}

footer div.email-wrapper>button.subscribe {
  position: absolute;
  width: 40%;
  right: 0;
  text-align: center;
  font-size: 1em;
  height: 3.4rem;
  padding: 0.5rem;
  /* Adjust padding as needed */
  box-sizing: border-box;
  /* Include padding and border in the total width and height */
  outline: none;
}

footer div.bottom-nav {
  height: auto;
}

footer div.copyrights {
  background-color: #1e213e;
  max-width: 100%;
}

footer div.copyrights>p {
  padding: 20px 0;
  text-align: center;
  color: #00e9e7;
}

footer .input-group {
  width: 350px;
  margin-right: 10px;
  margin-left: auto;
}

footer div.bottom-nav {
  text-align: left;
}

footer a {
  text-decoration: none;
  color: rgba(189, 185, 240, 1);
}

footer div.social-icons div.col {
  padding-left: 0;
}

footer div.social-icons {
  margin: 5px 0;
}

footer div.social-icons a {
  border: none;
  border-radius: 50%;
}

footer div.social-icons a:hover {
  box-shadow: 0 0 15px #ffffff;
  border-radius: 50%;
  transition: all 0.1s ease;
}

.footer-list li {
  margin-bottom: 0.2rem;
}

footer>div.footer-sec div.row.badges>div.col-3 {
  margin-right: 0.5rem;
}

footer .collap-title .fa-chevron-down::before {
  content: "\f107";
  color: #ffffff;
}

@media (min-width: 992px) {
  footer div.input-button-wrapper {
    margin-top: 6.8em;
  }
}

/** Media Queries for Responsiveness */
@media (max-width: 1367px) {
  footer div.badges img {
    height: 80px;
    width: 80px;
  }

  .email-wrapper>button.subscribe {
    font-size: 18px;
  }
}

@media (max-width: 1201px) {
  footer div.input-button-wrapper {
    margin-top: 2em;
  }

  footer div.social-icons img {
    height: 40px;
    width: 40px;
  }

  footer div.badges img {
    height: 70px;
    width: 70px;
  }

  footer div.contact-icon {
    padding-right: 5px;
    margin-bottom: auto;
    margin-top: 5px;
  }
}

@media (max-width: 1025px) {

  footer>div.footer-sec {
    font-size: 14px;
  }

  footer div#logo {
    margin-bottom: 2rem !important;
  }

  footer>div.footer-sec p {
    font-size: 16px;
  }

  footer>div.footer-sec h5 {
    font-size: 20px;
  }

  footer div.social-icons div.col {
    padding-right: 0;
  }

  footer div.social-icons {
    margin: 0 0;
  }

  div.social-icons img {
    height: 40px;
    width: 40px;
  }

  footer div.contact-icon {
    padding-right: 5px;
  }
}

@media (max-width: 991px) {
  footer div.copyrights>p {
    padding: 1.5% 0;
    text-align: center !important;
  }
}

@media (max-width: 768px) {
  footer .mob-footer-content .footer-collapsible-list {
    display: flex;
    justify-content: space-between;
  }

  footer .mob-footer-content .footer-collapsible-list .fa-chevron-down:before {
    color: #ffffff;
    content: "\f107";
  }
}

@media (max-width: 576px) {
  footer div.social-icons img {
    height: auto;
    width: auto;
  }

  footer div.badges img {
    height: 80px;
    width: 80px;
}
}

@media (max-width: 376px) {
  footer div.social-icons img {
    height: 40px;
    width: auto;
  }

  footer div.badges img {
    height: 70px;
    width: 70px;
}
}

@media (max-width: 321px) {
  footer div.email-wrapper>button.subscribe {
    font-size: 0.8em !important;
  }
}