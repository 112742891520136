/* header.header-section .header-right {
    display: flex;
    justify-content: space-between;
    width: 100%;
} */

header.header-section>div.container {
    width: 100%;
    max-width: 100%;
}

div.header-wrapper div.logo {
    margin-left: 5%
}

.header-section .header-wrapper .logo {
    width: 86px;
    height: 42px;    
}

header div.container {
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
}

header ul.menu .menu-link {
    font-size: 16px;
}

header .mobile-header ul.menu .menu-link {
    font-size: 18px;
}

.mobile-header .menu-link.active {
    color: #0044C6 !important;
}

.mobile-header ul.menu.active li {
    margin-bottom: 5px;
}

.no-hover:hover {
    color: #000000 !important;
}

@media (max-width: 991px) {
header .mobile-header .submenu-toggle a#neoin-mobile-header ul.submenu {
        transition: all 0.3s ease;
    }
    
header .mobile-header .submenu-toggle a {
    padding: 5px 30px 5px 10px !important;
}

header .mobile-header .menu-link:hover {
    color: #0044C6 !important;
}

.mobile-header .company-submenu i.fa-chevron-down {
    font-size: 18px;
} 
}