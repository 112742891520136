div.banner-content-9{
    max-width: 998px;
}

div.banner-content-9 p {
    max-width: 671px;
}

.banner-shape-9 {
    left: 50%;
    transform: translateX(-50%);
    width: 101%;
    z-index: 2;
    bottom: -0.3%;
}


/* #banner-btn {
    background: -webkit-linear-gradient(143.48deg, #0044c6 6.39%, #1150C9 55.07%, #00E9E7 108.81%);
    font-size: 20px;
} */
#banner-btn {
    background: -webkit-linear-gradient(143.48deg, #0044c6 6.39%, #1150C9 55.07%, #00E9E7 108.81%);
    font-size: 16px;
    height: 52px;
  }
  
  #banner-btn-flex {
    background: -webkit-linear-gradient(143.48deg, #0044c6 6.39%, #1150C9 55.07%, #00E9E7 108.81%);
    font-size: 16px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #btn-no-bg {
    font-size: 16px;
    height: 52px;
  }
  
  #btn-no-bg-flex {
    font-size: 16px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  

