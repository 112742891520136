.lightbox {
  position: fixed; /* Use fixed position for Lightbox to keep it above the items */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px;
  z-index: 1000; /* Set a high z-index to ensure it appears above other elements */
}

.lightbox__inner {
  position: relative;
  top:25%;
  left:30%;
  width: 620px;
  height: 424px;
  max-width: 600px;
  min-height: 400px;
  background: white;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0,0,0,0.80);
  text-align: center;
}
.lightbox__image {
  display: block;
  margin: 0 auto; 
}

.lightbox__title{
  font-weight: bold;
  text-align: center;
  margin: 20px 20px;
}


.lightbox__close {
  width: auto;
  background: none;
  border: none !important;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  font-size: 40px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.lightbox__close:hover {
  color: rgba(0, 0, 0, 0.8);
  border: none !important;
  outline: none !important;
  
}

/* Media queries
@media screen and (max-width: 768px) {
.lightbox__inner {
  max-width: 80%;
  min-height: 60%;
  position: relative;
  top:20%;
  left:10%;
}
}

@media screen and (max-width: 480px) {
.lightbox__inner {
  max-width: 60%;
  min-height: 50%;
  position: relative;
  top:10%;
  left:5%;
}
}
@media screen and (max-width: 1497px) {
.lightbox__inner {
  max-width: 70%;
  min-height: 60%;
  position: relative;
  top:20%;
  left:25%;
}
} */

/* Media queries */
@media screen and (max-width: 475px) {
  .lightbox__inner {
    max-width: 90%;
    min-height: 70%;
    position: fixed;
    left: 5%;
    top: 100px;
  } 
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .lightbox__inner {
    max-width: 80%;
    min-height: 65%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    margin-top: 10%;

  }
}

@media screen and (min-width: 769px) and (max-width: 1497px) {
  .lightbox__inner {
    max-width: 70%;
    min-height: 60%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

