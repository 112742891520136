@mixin scroll-style($size: 2px, $thumb: none, $thumb-color: grey, $thumb-radius: 10px, $track-color: transparent, $track-radius: 10px) {
  @supports not (selector(::-webkit-scrollbar-thumb)) {
    scrollbar-color: $thumb-color $track-color;
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: $size;
    height: $size;

    &-track {
      background-color: $track-color;
      border-radius: $track-radius;
    }

    &-thumb {
      background-image: $thumb;
      background-size: 200%;
      border-radius: $thumb-radius;
      height: 20%;
    }
  }
}

body {
  @include scroll-style(
  $size: 12px,
  $thumb: -webkit-linear-gradient(230deg, #0044c6 6.39%, #1150C9 55.07%, #00E9E7 108.81%),
  $thumb-color: #00aeff,
  $thumb-radius: 20px,
  $track-color: rgb(255 255 255 / 10%),
  $track-radius: 20px);
}
