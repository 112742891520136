.image-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; /* Hides overflowed content */
  }
  
  .image-carousel img {
   
    max-width: 100%;
    max-height: 80vh;
    transition: transform 0.9s ease; /* Add transition effect */
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s ease-out;
    border-radius: 0;
    background-color: transparent;
  }

  .arrow:active {
    border-radius: 20%;
    background-color: #00e4c683;
  }
  
  .left {
    left: 20px;
  }
  
  .right {
    right: 20px;
  }
