:root {
    --x03-on-primary-high-emphasis: rgba(255, 255, 255, 1);
    --x00-on-surface-medium-emphasis: rgba(0, 0, 0, 0.6);
    --x00-on-surface-high-emphasis: rgba(0, 0, 0, 0.87);
    --outline: rgba(0, 0, 0, 0.12);
    --x01-primary500: rgba(98, 0, 238, 1);
    --active-text-font-family: "Roboto", Helvetica;
    --active-text-font-weight: 400;
    --active-text-font-size: 14px;
    --active-text-letter-spacing: 0px;
    --active-text-line-height: normal;
    --active-text-font-style: normal;
    --button-font-family: "Roboto", Helvetica;
    --button-font-weight: 500;
    --button-font-size: 14px;
    --button-letter-spacing: 1.25px;
    --button-line-height: 16px;
    --button-font-style: normal;
    --subtitle-1-font-family: "Roboto", Helvetica;
    --subtitle-1-font-weight: 400;
    --subtitle-1-font-size: 16px;
    --subtitle-1-letter-spacing: 0.15000000596046448px;
    --subtitle-1-line-height: 24px;
    --subtitle-1-font-style: normal;
    --web-body-1-strong-font-family: "Segoe UI", Helvetica;
    --web-body-1-strong-font-weight: 400;
    --web-body-1-strong-font-size: 14px;
    --web-body-1-strong-letter-spacing: 0px;
    --web-body-1-strong-line-height: 20px;
    --web-body-1-strong-font-style: normal;
    --web-caption-2-font-family: "Segoe UI", Helvetica;
    --web-caption-2-font-weight: 400;
    --web-caption-2-font-size: 10px;
    --web-caption-2-letter-spacing: 0px;
    --web-caption-2-line-height: 14px;
    --web-caption-2-font-style: normal;
    --caption-font-family: "Roboto", Helvetica;
    --caption-font-weight: 400;
    --caption-font-size: 12px;
    --caption-letter-spacing: 0.4000000059604645px;
    --caption-line-height: 16px;
    --caption-font-style: normal;
    --elevation-light-shadow-04: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
    --color-primary-color: rgba(0, 68, 198, 1);
    --color-neutral-background-1-rest: var(--global-colors-grey-white);
    --color-neutral-foreground-1-rest: var(--global-colors-grey-14);
    --color-neutral-foreground-2-hover: var(--global-colors-grey-14);
    --color-neutral-foreground-3-rest: var(--global-colors-grey-38);
    --color-neutral-stroke-transparent-rest: rgba(255, 255, 255, 0);
    --color-primary-color-duplicate: rgba(0, 68, 198, 1);
    --global-colors-grey-14: rgba(36, 36, 36, 1);
    --global-colors-grey-38: rgba(97, 97, 97, 1);
    --global-colors-grey-white: rgba(255, 255, 255, 1);
    --global-colors-grey-68: rgba(173, 173, 173, 1);
    --corner-radius-medium-duplicate: var(--global-tokens-corner-radius-40);
    --global-tokens-corner-radius-40: 4px;
    --global-tokens-colors-neutral-grey-16: rgba(41, 41, 41, 1);
  }
  
  /*
  
  To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:
  
  <body data-color-mode="light">
      <!-- the rest of your content -->
  </body>
  
  You can apply the theme on any DOM node, not just the `body`
  
  */
  
  [data-color-mode="light"] {
    --color-neutral-background-1-rest: var(--global-colors-grey-white);
    --color-neutral-foreground-1-rest: var(--global-colors-grey-14);
    --color-neutral-foreground-2-hover: var(--global-colors-grey-14);
    --color-neutral-foreground-3-rest: var(--global-colors-grey-38);
  }
  
  [data-color-mode="dark"] {
    --color-neutral-background-1-rest: var(--global-tokens-colors-neutral-grey-16);
    --color-neutral-foreground-1-rest: var(--global-colors-grey-white);
    --color-neutral-foreground-2-hover: var(--global-colors-grey-white);
    --color-neutral-foreground-3-rest: var(--global-colors-grey-68);
  }
  