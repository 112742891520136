section.download-links {
  background-color: #202342;
  height: auto;
  min-width: 320px;
}

section.download-links h1,
section.download-links h2,
section.download-links h3,
section.download-links p {
  color: #ffffff;
}

section.download-links h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 76px;
  font-weight: 700;
}

section.download-links div.get-started p {
  text-align: justify;
  margin-right: auto;
}

section.download-links > div.links {
  max-width: 100%;
  padding-top: 60px;
  padding-bottom: 0;
  margin: 0 40px;
  overflow: hidden;
}

section.download-links div.qr-frame {
  align-content: center;
  padding-left: 5%;
  /* padding-right: 100px; */
  margin-right: -60px;
}

section.download-links div.store-links {
  padding-top: 4%;
}

section.download-links div.store-links img {
  height: 55px;
  width: auto;
}

section.download-links div.store-links div.col-md-4,
section.download-links div.store-links div.col-sm-6 {
  padding-right: 35%;
  text-align: left;
  max-width: 50%;
}

section.download-links div.get-started {
  text-align: left;
}

section.download-links div.get-started {
  padding-top: 20px;
}

section.download-links h1 {
  text-align: left;
  white-space: nowrap;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.5em;
  font-weight: 700;
}

div.qr-frame {
  display: flex;
  justify-content: space-around;
}

.download-links .qr-frame img {
  max-width: 75%; /* Ensure images don't exceed container width */
  height: auto; /* Maintain aspect ratio */
}

.download-links .get-started img {
  /* margin-bottom: 0; */
  max-width: 75%; /* Ensure images don't exceed container width */
  height: auto; /* Maintain aspect ratio */
  padding: 0;
}

div.get-started {
  margin-top: 4%;
  width: 770px;
}

section.download-links p {
  margin-right: 30%;
}

section.download-links > div.links {
  max-width: 85%;
  padding-top: 60px;
  padding-bottom: 0;
  margin: 0% auto;
  overflow: hidden;
}

section.download-links a.qr-image-link-wrapper {
  width: 95%;
  text-align: center;
}

@media (min-width: 1920px) {
    section.download-links div.row.store-links img {
        height: auto;
        width: auto;
      }

      section.download-links  div.get-started h1 {
        font-size: 3em;
      }

      section.download-links  div.get-started > p {
        font-size: 1.5em;
      }
    
}

@media (max-width: 1367px) {
  section.download-links div.row.store-links img {
    height: 52px;
    width: 170px;
  }
}

@media (max-width: 1241px) {
  section.download-links div.row.store-links img {
    height: 100%;
    width: 120%;
  }
}

@media (max-width: 992px) {
  .container.row.links {
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    /* Stack columns vertically */
  }

  div.get-started {
    width: auto;
  }

  section.download-links div.store-links div.col-md-4,
  section.download-links div.store-links div.col-sm-6 {
    padding-right: 0;
    text-align: center;
  }

  section.download-links div.store-links div.res-gplay {
    text-align: right;
    padding-right: 2%;
  }

  section.download-links div.store-links div.res-astore {
    text-align: left;
    padding-left: 2%;
  }

  section.download-links div.store-links {
    padding-top: 0%;
  }

  section.download-links h1 {
    text-align: center;
    font-size: 2.5em;
  }

  section.download-links div.get-started > p {
    text-align: center !important;
    font-size: 1.2em;
    margin-right: 0;
    white-space: pre-line;
  }

  section.download-links div.qr-frame {
    display: flex;
    justify-content: center; /* Align horizontally to center */
    align-items: center; /* Align vertically to center */
    padding: 0;
    margin-right: 0;
    margin-left: 0;
    margin-top: 1rem;
  }

  section.download-links div.qr-frame img {
    height: auto;
    width: auto;
  }

  .store-links {
    display: flex;
    flex-direction: row; /* Display store links side-by-side */
    justify-content: center; /* Center align store links */
    align-items: center; /* Center align store links vertically */
    margin-top: 2%; /* Add margin between qr-frame and store-links */
  }

  section.download-links div.row.store-links img {
    height: 3em;
    width: auto;
  }

  .download-links h1 {
    font-size: 28px;
    margin-bottom: 0.8em;
  }

  .download-links p:last-child {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 576px) {
  section.download-links > div.links {
    max-width: 100%;
  }
}

@media (max-width: 521px) {
  section.download-links div.row.store-links img {
    height: 2.5em;
    width: auto;
  }

  section.download-links  div.get-started h1 {
    font-size: 22px;
  }

  section.download-links div.get-started > p {
    font-size: 16px;
  }
}

@media (max-width: 426px) {
  /* .links {
      margin: 0 10px; 
      padding: 60px 10px 0 10px; 
    } */

  section.download-links div.row.store-links img {
    height: 2.5em;
  }
}

@media (max-width: 376px) {
  section.download-links div.get-started {
    width: 104%;
  }

  section.download-links div.row.store-links img {
    height: 1.8em;
  }
}